/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = function () {
    // Don't track while developing.
    /*if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
        fbq('track', 'ViewContent')
    }*/
}
