import { getSunrise, getSunset } from 'sunrise-sunset-js'
export const pathLib = {
    match: {
        '/': 'home',
        '/cenik': 'sidewaysElipse',
        '/pvcalc': 'pvcalc',
        '/kisik/': 'kisik',
        '/about': 'about',
        '/tehnologija/': 'elipse',
        '/blog/': 'elipse',
        '/legal/': 'smallBox',
        '/mikro-elektrarna/': 'mikro',
        '/industrija/': 'industrija',
        '/soncna-elektrarna/': 'home',
        '/energetska-skupnost/': 'kisik2',
        '/vzdrzevanje-svetovanje/': 'kisik2',
        '/samooskrbna-elektrarna/': 'home',
        '/en/': 'home',
        '/en/cenik': 'sidewaysElipse',
        '/en/pvcalc': 'pvcalc',
        '/en/kisik/': 'kisik',
        '/en/about': 'about',
        '/en/tehnologija/': 'elipse',
        '/en/blog/': 'elipse',
        '/en/legal/': 'smallBox',
        '/en/mikro-elektrarna/': 'mikro',
        '/en/industrija/': 'industrija',
        '/en/soncna-elektrarna/': 'home',
        '/en/energetska-skupnost/': 'kisik2',
        '/en/vzdrzevanje-svetovanje/': 'kisik2',
        '/en/samooskrbna-elektrarna/': 'home',
        // default is specific blog post
        default: 'line',
    },
    points: {
        home: [
            {
                // Set the points in relative dimension x,y
                point: [0, 580], //x(%),y(px)
                hVector: [40, 12], //handle vector deg,len
            },
            {
                point: [30, 550],
                hVector: [20, 15],
            },
            {
                point: [70, 450],
                hVector: [0, 15],
            },
            {
                point: [100, 300],
                hVector: [-10, 10],
            },
        ],
        kisik: [
            {
                // Set the points in relative dimension x,y
                point: [0, 550], //x,y
                hVector: [-23, 12], //handle vector deg,len
            },
            {
                point: [35, 550],
                hVector: [-50, 20],
            },
            {
                point: [60, 450],
                hVector: [-40, 15],
            },
            {
                point: [100, 350],
                hVector: [-40, 20],
            },
        ],
        line: [
            {
                // Set the points in relative dimension x,y
                point: [0, 580], //x,y
                hVector: [0, 12], //handle vector deg,len
            },
            {
                point: [30, 400],
                hVector: [15, 20],
            },
            {
                point: [60, 520],
                hVector: [20, 15],
            },
            {
                point: [100, 260],
                hVector: [40, 20],
            },
        ],
        elipse: [
            {
                // Set the points in relative dimension x,y
                point: [0, 370], //x,y
                hVector: [0, 12], //handle vector deg,len
            },
            {
                point: [30, 290],
                hVector: [15, 20],
            },
            {
                point: [60, 320],
                hVector: [20, 15],
            },
            {
                point: [100, 80],
                hVector: [-80, 15],
            },
        ],
        kisik2: [
            {
                // Set the points in relative dimension x,y
                point: [0, 580], //x,y
                hVector: [-23, 12], //handle vector deg,len
            },
            {
                point: [35, 550],
                hVector: [-50, 20],
            },
            {
                point: [60, 450],
                hVector: [-40, 15],
            },
            {
                point: [100, 350],
                hVector: [-40, 20],
            },
        ],
        mikro: [
            {
                // Set the points in relative dimension x,y
                point: [0, 580], //x(%),y(px)
                hVector: [40, 12], //handle vector deg,len
            },
            {
                point: [30, 650],
                hVector: [40, 15],
            },
            {
                point: [70, 450],
                hVector: [30, 15],
            },
            {
                point: [100, 200],
                hVector: [-10, 10],
            },
        ],
        industrija: [
            {
                // Set the points in relative dimension x,y
                point: [0, 620], //x(%),y(px)
                hVector: [60, 12], //handle vector deg,len
            },
            {
                point: [30, 650],
                hVector: [40, 15],
            },
            {
                point: [70, 450],
                hVector: [30, 15],
            },
            {
                point: [100, 600],
                hVector: [-10, 10],
            },
        ],
        smallBox: [
            {
                // Set the points in relative dimension x,y
                point: [0, 80], //x(%),y(px)
                hVector: [0, 12], //handle vector deg,len
            },
            {
                point: [30, 80],
                hVector: [0, 15],
            },
            {
                point: [70, 80],
                hVector: [0, 15],
            },
            {
                point: [100, 80],
                hVector: [-0, 10],
            },
        ],
    },
    returnPath: (match) => {
        //Needs an exact match
        if (match in pathLib.match) {
            return {
                pathName: pathLib.match[match],
                pathPoints: pathLib.points[pathLib.match[match]],
            }
        }
        return { pathName: 'line', pathPoints: pathLib.points.line }
    },
}

export const getTheme = () => {
    const currentTime = new Date().getHours()
    if (
        currentTime > getSunset(46.0569, 14.5058).getHours() ||
        currentTime < getSunrise(46.0569, 14.5058).getHours()
    ) {
        return 'dark'
    }
    return 'light'
}
