import React, { useEffect, useState } from 'react'
import Header from '../components/header/Header'
import Footer from '../components/Footer'
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import Devbar from '../components/Devbar'
import '../styles/index.scss'
import favicon from '../images/favicon.ico'
import { Helmet } from 'react-helmet'
import { Suspense } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

export default ({ children, location }) => {
    const [kWh, setkWh] = useState(0)
    const [theme, setTheme] = useState('light')
    const [path, setPath] = useState('/')
    const toggleTheme = () => {
        if (theme === 'light') setTheme('dark')
        else setTheme('light')
    }
    useEffect(() => {
        setPath(location.pathname)
    }, [location])

    useEffect(() => {
        const currentTime = new Date().getHours()
        if (
            currentTime > getSunset(46.0569, 14.5058).getHours() ||
            currentTime < getSunrise(46.0569, 14.5058).getHours()
        ) {
            setTheme('dark')
        }
    }, [])

    const { t, i18n } = useI18next()

    return (
        <Suspense fallback="">
            <SiteContext.Provider
                value={{ theme, toggleTheme, path, setPath, kWh, setkWh }}
            >
                <Helmet>
                    <link rel="icon" href={favicon} />
                    <title>{t('metadata.title')}</title>
                    <meta
                        lang={t('metadata.lang')}
                        name="description"
                        content={t('metadata.description')}
                    />
                </Helmet>
                <Header />
                {children}

                <Footer />
            </SiteContext.Provider>
        </Suspense>
    )
}

export const SiteContext = React.createContext({
    theme: 'light',
    toggleTheme: () => {},
})
