import React, { useState, useContext } from 'react'
import { SiteContext } from '../layouts'
import {Box} from 'react-bulma-components';
import styled from 'styled-components'

const Option = styled.div`
    background-color: rgba(47, 47, 47, 0.56);
    border-radius: 50px;
    min-width: 150px;
    position: absolute;
    right: 0;
    color: white;
    padding: 0.125rem;
    bottom: ${(props) => 20 + props.order * 35}px;
`
const StyledBox = styled(Box)`
    && {
        position: fixed;
        bottom: 50px;
        right: 50px;
        height: 45px;
        width: 45px;
        z-index: 9999;
        text-align: center;
        padding: 0;
        border-radius: 50%;
        overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
    }
`
export default function () {
    const context = useContext(SiteContext)
    const [open, setOpen] = useState(false)

    return (
        <StyledBox open={open}>
            <span
                role="img"
                aria-label={'pie'}
                style={{
                    lineHeight: '45px',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                }}
                onClick={() => setOpen(!open)}
            >
                🍉
            </span>
            <Option order={1}>
                <label>
                    Theme:
                    <input
                        name="isDayTime"
                        type="checkbox"
                        checked={context.theme === 'light'}
                        onChange={() => context.toggleTheme()}
                    />
                </label>
            </Option>

            <Option order={2}>
                <label>
                    Location: {context.path}
                </label>
            </Option>
        </StyledBox>
    )
}
